import { deburr, sortBy } from "lodash"
import { Store, ExternalReviewSource } from "./Store"

export const getSlug = (item: {
  category: string
  title: string
  id: string
}) => {
  const normTitle = deburr(
    (item.title as string)
      .toLowerCase()
      .replace(/[^a-zA-Z\d\s]/gi, "")
      .replace(/\s/gi, "-")
  )

  return `/${item.category}/${encodeURIComponent(
    normTitle
  )}-${item.id.substring(0, 5)}`
}

const AWIN_PUBLISHER_ID = 777605

const DRINKS_AND_CO_ADVERTISER_ID = 17718
const THE_WHISKY_EXCHANGE_ADVERTISER_ID = 400

// Low is better
const storeImagePriority: Record<Store, number> = {
  [Store.MasterOfMalt]: 0,
  [Store.TheWhiskyShop]: 0,
  [Store.HouseOfMalt]: 0,
  [Store.LochFyneWhiskies]: 0,
  [Store.Amazon]: 1,
  [Store.DrinksAndCo]: 1,
  [Store.TheWhiskyExchange]: 2,
}

const urlMapping: { [store in Store]: (url: string) => string } = {
  [Store.MasterOfMalt]: url =>
    `https://scripts.affiliatefuture.com/AFClick.asp?affiliateID=348768&merchantID=7042&programmeID=24815&mediaID=-1&tracking=&url=${encodeURIComponent(
      url
    )}`,
  [Store.Amazon]: url => `${url}?tag=tlp0d-21`,
  [Store.TheWhiskyShop]: url =>
    `https://scripts.affiliatefuture.com/AFClick.asp?affiliateID=348768&merchantID=7061&programmeID=25059&mediaID=0&tracking=&afsource=20&url=${encodeURIComponent(
      url
    )}`,
  [Store.HouseOfMalt]: url =>
    `https://scripts.affiliatefuture.com/AFClick.asp?affiliateID=348768&merchantID=7241&programmeID=26493&mediaID=0&tracking=&afsource=20&url=${encodeURIComponent(
      url
    )}`,
  [Store.LochFyneWhiskies]: url =>
    `https://scripts.affiliatefuture.com/AFClick.asp?affiliateID=348768&merchantID=7062&programmeID=25070&mediaID=0&tracking=&afsource=20&url=${encodeURIComponent(
      url
    )}`,
  [Store.DrinksAndCo]: url =>
    `https://www.awin1.com/cread.php?awinmid=${DRINKS_AND_CO_ADVERTISER_ID}&awinaffid=${AWIN_PUBLISHER_ID}&ued=${encodeURIComponent(
      url
    )}`,
  [Store.TheWhiskyExchange]: url =>
    `https://www.awin1.com/cread.php?awinmid=${THE_WHISKY_EXCHANGE_ADVERTISER_ID}&awinaffid=${AWIN_PUBLISHER_ID}&ued=${encodeURIComponent(
      url
    )}`,
}

export const getAffiliateUrl = (url: string, store: Store) => {
  return urlMapping[store]?.(url) ?? url
}

export const getReviewAffiliateUrl = (
  url: string,
  store: ExternalReviewSource
) => {
  return urlMapping[store]?.(url) ?? url
}

export const selectBestImageUrl = (
  listings: Array<{ product_image_url: string; store: string }>
) => {
  return sortBy(
    listings,
    listing => storeImagePriority[listing.store as Store]
  )?.find(listing => listing.product_image_url)?.product_image_url
}

export const formatTagValues = (
  type:
    | "country"
    | "region"
    | "distillery"
    | "style"
    | "age"
    | "abv"
    | "feature",
  values?: string[]
) => {
  if (!values || values.length === 0) return
  switch (type) {
    case "abv":
      return values.join("%, ")
    case "age":
      return values.join(`Years, `)
    default:
      return values.join(", ")
  }
}

export const isNotSSR = () => typeof window !== `undefined`
