import { getFirebaseApp } from "./firebase"
import { getApolloClient } from "../../apollo/ApolloClient"
import { gql } from "@apollo/client"

const upsertUserMutation = gql`
  mutation UpsertUser($name: String, $email: String) {
    insert_users_one(
      object: { name: $name, email: $email }
      on_conflict: { constraint: users_pkey, update_columns: [email] }
    ) {
      id
      name
      private {
        email
      }
    }
  }
`

const ensureUser = async (name?: string | null, email?: string | null) => {
  const client = getApolloClient()
  await client.mutate({
    mutation: upsertUserMutation,
    variables: { name, email },
  })
}

export const syncUserWithHasura = () => {
  getFirebaseApp().then(firebaseApp => {
    firebaseApp.auth().onIdTokenChanged(user => {
      if (user) {
        ensureUser(user.displayName, user.email).catch(e => console.error(e))
      }
    })
  })
}
