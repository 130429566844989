import fetch from "isomorphic-fetch"
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { getAuthToken } from "../service/firebase/auth"

const API_HOST = process.env.API_HOST || process.env.GATSBY_API_HOST

const cache = new InMemoryCache()

const authLink = setContext(async (_, { headers }) => {
  let token = await getAuthToken()
  let authHeaders = { ...headers }
  if (token) {
    authHeaders["Authorization"] = `Bearer ${token}`
  }

  return {
    headers: authHeaders,
  }
})

const link = authLink.concat(
  createHttpLink({ uri: `${API_HOST}/v1/graphql`, fetch })
)

let client: ApolloClient<NormalizedCacheObject> | undefined
export const getApolloClient = () => {
  if (client) return client

  client = new ApolloClient({
    link,
    cache: cache,
    ssrMode: typeof window === "undefined",
  })

  return client
}
