import { isNotSSR } from "../../utils"
import type firebaseType from "firebase/app"
import EventEmitter from "eventemitter3"

export enum Events {
  INITIALIZED = "INITIALIZED",
}

const FIREBASE_CONFIG = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

export type FirebaseUser = firebaseType.User
export type FirebaseIdToken = firebaseType.auth.IdTokenResult

export let firebaseApp: firebaseType.app.App | undefined
export let AUTH_PROVIDERS: string[] = []
export const firebaseInitializedEmitter = new EventEmitter()

export const intializeFirebase = async () => {
  if (isNotSSR()) {
    const [fb] = await Promise.all([
      import("firebase/app"),
      import("firebase/performance"),
      import("firebase/auth"),
    ])

    // Initialize Firebase
    firebaseApp = fb.default?.initializeApp(FIREBASE_CONFIG)
    firebaseApp?.performance()

    // var auth = firebaseApp.auth()
    // auth.useEmulator("http://localhost:9099")

    AUTH_PROVIDERS = [fb.default.auth.EmailAuthProvider.PROVIDER_ID]

    firebaseInitializedEmitter.emit(Events.INITIALIZED, {
      firebaseApp,
    })
  }
}

export const getFirebaseApp = async (): Promise<firebaseType.app.App> => {
  if (firebaseApp) return firebaseApp

  return new Promise(res =>
    firebaseInitializedEmitter.on(Events.INITIALIZED, ({ firebaseApp }) =>
      res(firebaseApp)
    )
  )
}
