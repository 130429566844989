import React from "react"
import { globalHistory } from "@reach/router"
import { QueryParamProvider } from "use-query-params"
import { isNotSSR } from "./utils"
import { intializeFirebase } from "./service/firebase/firebase"
import { syncUserWithHasura } from "./service/firebase/syncUserWithHasura"

intializeFirebase()
syncUserWithHasura()

export const WrapQueryParamProvider: React.FC = ({ children }) =>
  isNotSSR() ? (
    <QueryParamProvider {...{ path: "/" }} reachHistory={globalHistory}>
      {children}
    </QueryParamProvider>
  ) : (
    <>{children}</>
  )
