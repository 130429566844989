import React from "react"
import { WrapApolloProvider } from "./apollo/WrapApolloProvider"
import { WrapQueryParamProvider } from "./WrapQueryParamProvider"

export const wrapRootElement = ({ element }: any) => {
  return (
    <WrapApolloProvider>
      <WrapQueryParamProvider>{element}</WrapQueryParamProvider>
    </WrapApolloProvider>
  )
}
