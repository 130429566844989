export enum Store {
  MasterOfMalt = "MasterOfMalt",
  Amazon = "Amazon",
  TheWhiskyShop = "TheWhiskyShop",
  HouseOfMalt = "HouseOfMalt",
  LochFyneWhiskies = "LochFyneWhiskies",
  DrinksAndCo = "DrinksAndCo",
  TheWhiskyExchange = "TheWhiskyExchange",
}

export enum ExternalReviewSource {
  MasterOfMalt = "MasterOfMalt",
  TheWhiskyExchange = "TheWhiskyExchange",
}
