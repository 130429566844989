import React, { FC } from "react"
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline"
import amber from "@material-ui/core/colors/amber"
import indigo from "@material-ui/core/colors/indigo"

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core"

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: amber,
      secondary: indigo,
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            WebkitFontSmoothing: "auto",
          },
          body: {
            margin: "0px",
          },
        },
      },
    },
  })
)

export const GlobalThemeProvider: FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>{children}</ScopedCssBaseline>
    </ThemeProvider>
  )
}
