// import { useAuthState } from "react-firebase-hooks/auth"
import { firebaseApp, FirebaseIdToken, getFirebaseApp } from "./firebase"

let authToken: FirebaseIdToken | undefined

export const getAuthToken = async (): Promise<string | undefined> => {
  await runEnsureAuthToken()
  return authToken?.token
}

// Helper to make sure we run the `ensureAuthToken` at most once at a time
let ensureAuthTokenPendingPromise: Promise<void> | undefined
const runEnsureAuthToken = async () => {
  if (ensureAuthTokenPendingPromise) return ensureAuthTokenPendingPromise
  try {
    ensureAuthTokenPendingPromise = ensureAuthToken()
    await ensureAuthTokenPendingPromise
  } finally {
    ensureAuthTokenPendingPromise = undefined
  }
}

const ensureAuthToken = async () => {
  // Make sure it's only called at most once at a time
  const currentUser = firebaseApp?.auth().currentUser

  if (!currentUser) {
    authToken = undefined
    return
  }

  let token = await currentUser?.getIdTokenResult()
  authToken = token
}
getFirebaseApp().then(firebaseApp => {
  firebaseApp.auth().onIdTokenChanged(() => {
    runEnsureAuthToken().catch(e => console.error(e))
  })
})
