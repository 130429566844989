// see https://github.com/GalenWong/galenwong.github.io/commit/226fff203d3a67ac8d813ed3df9cd1f6f0bdb7df
import React from "react"
import { GlobalThemeProvider } from "../../src/styles/globalTheme"

export function ThemeTopLevelProvider({ children /*initTheme*/ }) {
  // const [mode, setMode] = useState(initTheme)
  // const setTheme = isDark => {
  //   const val = isDark ? "dark" : "light"
  //   localStorage.setItem("theme", val)
  //   setMode(val)
  // }

  return <GlobalThemeProvider>{children}</GlobalThemeProvider>
}
