// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-redirect-page-tsx": () => import("./../../../src/templates/RedirectPage.tsx" /* webpackChunkName: "component---src-templates-redirect-page-tsx" */),
  "component---src-templates-style-page-tsx": () => import("./../../../src/templates/StylePage.tsx" /* webpackChunkName: "component---src-templates-style-page-tsx" */)
}

